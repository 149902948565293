// Image Imports
import linkedin from "../../assets/img/idx/rmloc/linkedin.webp";
import instagram from "../../assets/img/idx/rmloc/instagram.webp";
import facebook from "../../assets/img/idx/rmloc/facebook.webp";

export default function ResourcesSection() {
  return (
    <div className="section-content">
      <h2>Resources</h2>
	  <br/>
      <div className="section-group">
		<div className="links-group">
			<h4>Learn More</h4>
			{/* <a href="/blog">Blog</a> */}
			<a href="/pricing">Pricing</a>
			<a href="/faq">FAQ's</a>
		</div>
	  </div>
	  <br/><br/>
      <div className="section-group">
        <div className="links-group">
        	<h4>Our Socials</h4>
			<a href="https://www.linkedin.com/company/contentremoval" target="_blank" rel="noopener noreferrer">
				<img src={linkedin.src} alt="LinkedIn" />
				LinkedIn
			</a>
			<a href="https://www.instagram.com/contentremoval" target="_blank" rel="noopener noreferrer">
				<img src={instagram.src} alt="Instagram" />
				Instagram
			</a>
			<a href="https://www.facebook.com/ContentRemovalExperts" target="_blank" rel="noopener noreferrer">
				<img src={facebook.src} alt="Facebook" />
				Facebook
			</a>
        </div>
      </div>
    </div>
  );
} 